<template>
  <div class="d-flex flex-column full-height overflow-hidden">
    <document-toolbar
      :delete-document="doDelete"
      :revert="revert"
      :save="saveDocument"
      :show-cancel="!preview"
      :show-delete="canDelete"
      :show-save="showSave"
      :special-actions="actions"
      :tab="tab"
      no-history
    />
    <v-form
      ref="form"
      @input="validateForm"
      v-model="form"
      class="d-flex flex-column full-height overflow-hidden"
    >
      <div class="px-6 flex-grow-1 overflow-y-auto">
        <translations
          v-if="!preview"
          :label="$t('t.Name')"
          :translations.sync="document.name"
          :isNew="documentIsNew"
          :rules="required"
        />
        <v-list>
          <draggable
            v-model="document.columns"
            draggable=".draggable"
            @start="closePicker"
            @end="updateSetLive"
          >
            <v-list-item
              v-for="(colArg, index) in document.columns"
              :key="index"
              class="px-0"
              :class="{draggable: !colArg.pin}"
            >
              <v-list-item-action class="my-0">
                <v-icon v-if="!colArg.pin">{{$icon('i.Menu')}}</v-icon>
              </v-list-item-action>
              <v-list-item-content class="overflow-visible">
                <column-picker
                  bottom-element-selector="#panel-footer"
                  :clearable="false"
                  :selected-col-id.sync="colArg.col"
                  :selected-cur-id.sync="colArg.currency"
                  :consolidated.sync="colArg.consolidated"
                  :filters="{'document-types': cameleonDocType}"
                  :rules="required"
                  @picker-loaded="addedColumn(colArg.col)"
                  @update:selected-col-id="updateSetLive()"
                  @update:selected-cur-id="updateSetLive()"
                  @update:consolidated="updateSetLive()"
                  ref="columns"
                />
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex">
                  <pinned-btn
                    :is-pinned="colArg.pin"
                    @click="togglePin(index)"
                    small
                    v-if="index == 0"
                  />
                  <v-btn
                    icon
                    @click="removeColumn(index)"
                    v-if="document.columns.length > 1"
                  >
                    <v-icon color="red">{{$icon('i.Close')}}</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </draggable>
        </v-list>
      </div>
      <div
        id="panel-footer"
        class="d-flex flex-column"
      >
        <div class="d-flex justify-center px-6 pb-6">
          <v-btn-toggle
            class="border-radius"
            dense
            multiple
          >
            <v-btn
              class="opacity-full"
              color="primary"
              small
              @click="addExistingColumn()"
              :disabled="hasEmptyColumn"
            >
              {{$t('t.AddColumn')}}
            </v-btn>
            <v-menu
              offset-y
              top
              class="ml-6"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{$icon('i.ChevronDown')}}</v-icon>
                </v-btn>
              </template>
              <v-sheet class="border-radius">
                <v-list>
                  <v-list-item v-if="computedItems.length">
                    <v-list-item-title class="subtitle-2">
                      {{$t('t.AgingScenarios')}}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-for="item in computedItems"
                    :key="item.id"
                    @click="addAgingColumns(item.id)"
                  >
                    <v-list-item-title>
                      <v-icon small>{{$icon('i.AgingScenarios')}}</v-icon>
                      {{item.name}}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider v-if="computedItems.length && canCreateColumn" />
                  <v-list-item
                    v-if="canCreateColumn"
                    @click="createColumn()"
                  >
                    <v-list-item-title class="toggle-footer font-weight-bold">
                      <v-icon
                        small
                        color="primary"
                      >{{$icon('i.Columns')}}</v-icon>
                      {{$t('t.CreateColumn')}}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-sheet>
            </v-menu>
          </v-btn-toggle>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import DocPerm from '@/mixins/doc-perm'
import sidePanelController from '@/sidePanelController'
import Search, { GlobalSearch } from '@/pages/search/controllers'
import Preset from './preset'

export default {
  mixins: [DocPerm, Preset],
  components: {
    ColumnPicker: () => import('@/components/column-picker'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    Draggable: () => import('vuedraggable'),
    PinnedBtn: () => import('@/pages/search/components/filter/pinned-btn'),
    Translations: () => import('@/components/translations')
  },
  beforeDestroy () {
    const search = GlobalSearch.getActiveGridSearch(this.params.scope, this.params.targetDocTy)
    if (search) {
      if (this.isPristine && this.hasBeenSaved) {
        search.setColumnSet(this.documentId)
      } else {
        search.setColumnSet(undefined, true).execute()
      }
    }
    this.search = undefined
  },
  data () {
    return {
      search: new Search().chain(s => s.searchedDocumentTypes.include(['aging-scenarios']))
    }
  },
  computed: {
    actions () {
      const actions = []
      if (this.id) {
        actions.push({
          text: this.$t('t.Duplicate'),
          icon: this.$icon('i.Templates'),
          callback: this.duplicate
        })

        if (!(this.document?.isPublic ?? true) && (this.document?.canPublish ?? false)) {
          actions.push({
            text: this.$t('t.MakePublic'),
            icon: this.$icon('i.Eye'),
            callback: this.publish
          })
        }
      }
      return actions.length ? actions : undefined
    },
    cacheType () {
      return Preset.CacheType.ColumnSetDetail
    },
    canCreateColumn () {
      return this.$store.getters.currentUserHasPermission('GeneralSettings')
    },
    canDelete () {
      return !this.preview && !this.documentIsNew && this.document.canDelete
    },
    showSave () {
      return !this.preview && (this.documentIsNew ? (this.docPerm.canCreate ?? true) : (this.document?.canWrite ?? true))
    },
    canSave () {
      return this.form
    },
    computedItems () {
      return this.search?.items?.filter(p => p.viewType === this.params.targetDocTy) ?? []
    },
    culture () {
      return this.$store.getters.currentUser.culture
    },
    docPermDocType () {
      return 'column-sets'
    },
    hasEmptyColumn () {
      return this.document.columns?.some(c => !c?.col)
    },
    tab () {
      return this
    },
    title () {
      return this.document.name ? this.document.name[this.$store.getters.currentUser.culture] : this.$t('t.PresetSettings')
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        this.document = Object.assign(
          {},
          this.document,
          {
            id: this.documentId,
            columns: this.params?.columns ?? [],
            name: undefined,
            viewType: this.params.targetDocTy
          }
        )
        if (!this.document.columns.length) {
          this.addExistingColumn()
        }
      }
      this.updateSetLive()
      this.search.execute()
      this.validateForm()
    },
    async addAgingColumns (id) {
      const result = await this.$http().get(`/core/v6/aging/${id}/columns`)
      result?.data?.columns?.forEach(c => this.document.columns?.push({ col: c.id }))
      this.updateSetLive()
    },
    addExistingColumn () {
      this.document.columns?.push({ col: null })
    },
    createColumn () {
      sidePanelController.displayDocument(null, 'columns', { targetDocTy: this.document.viewType })
    },
    async doDelete () {
      await this.$http().delete(`/core/v6/cameleon/column-sets/${this.document.id}`)
      this.close()
    },
    duplicate () {
      sidePanelController.displayDocument(null, 'column-sets', { targetDocTy: this.params?.targetDocTy, scope: this.params?.scope, columns: this.document.columns })
    },
    publish () {
      this.document.isPublic = true
    },
    togglePin (index) {
      const item = this.document.columns[index] ?? {}
      this.$set(item, 'pin', !item.pin)
      this.updateSetLive()
    },
    // This method is called from the mixin preset.vue
    updateSetLive (doNotApply = true) {
      const columns = this.document?.columns?.filter(c => c.col) ?? []
      if (!columns.length) { return }

      const search = GlobalSearch.getActiveGridSearch(this.params.scope, this.params.targetDocTy)
      search?.setColumnSet(columns, doNotApply, this.document?.id)
      if (doNotApply) {
        search?.execute()
      }
    },
    documentToJSON () {
      return {
        cols: this.document.columns,
        docType: this.document.viewType,
        isPublic: this.document.isPublic,
        name: this.document.name,
        scope: this.params.scope ?? null
      }
    },
    async save () {
      const id = await this.savePreset('column-sets')
      GlobalSearch.getActiveGridSearch(this.params.scope, this.params.targetDocTy)?.setColumnSet(id).execute()
    }
  }
}
</script>

<style lang="stylus" scoped>
.overflow-visible
  overflow visible

.full-height
  height 100%

.opacity-full
  opacity 1 !important

.toggle-footer
  color var(--primary-color)
</style>
